<template>
  <v-dialog v-model="errorDialog" max-width="500px">
    <v-card>
      <v-card-title class="ABVhint-text">{{ errorMessage }}</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary darken-1" depressed @click="closeErrorDialog"
          >Close</v-btn
        >
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    errorDialog: Boolean,
    errorMessage: String
  },
  methods: {
    closeErrorDialog() {
      this.$emit("close");
    }
  }
};
</script>

<style>
.ABVhint-text {
  color: red;
  font-weight: normal;
  font-size: 11.5px;
}
</style>
